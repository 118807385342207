import React, { useState } from 'react';

const SpecialYearCard = ({ darkMode }) => {
  const [open, setOpen] = useState(false);

  const toggle = () => {
    setOpen(!open);
  };

  return (
    <div className="col-md-4 mb-3">
      <div className={`card ${darkMode ? 'dark-mode' : ''}`}>
        <div className="card-body">
          <h4 className="card-title"><i className="fas fa-star"></i> Ein ganz besonderes Jahr</h4>
          <p className="card-text">Dieses Jahr feiern wir das Woosmer Open Air zum fünften Mal und haben uns dafür etwas ganz Besonderes überlegt. Es wird spannende Geschichten geben, über die wir euch jetzt noch nicht zu viel verraten möchten.</p>
          <div className={`collapse ${open ? 'show' : ''}`}>
          <img src={`${process.env.PUBLIC_URL}/images/Wiese.jpg`} alt="Father and Sons" className="img-fluid my-2" />
            <p className="card-text">Kommt vorbei und erlebt mit uns unvergessliche Momente, die wir gemeinsam schaffen werden.</p>
          </div>
          <button className="btn btn-primary card-button" onClick={toggle}>
            {open ? 'Weniger lesen' : 'Weiterlesen'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SpecialYearCard;
