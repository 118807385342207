import React from 'react';

const AnfahrtCard = ({ darkMode }) => {
  return (
    <div className="col-md-4 mb-3">
      <div className={`card ${darkMode ? 'dark-mode' : ''}`}>
        <div className="card-body">
          <h4 className="card-title"><i className="fas fa-map-marker-alt"></i> Anfahrt</h4>
          <p className="card-text">Du willst nicht "Woosmer Mühle" bei Google-Maps eingeben? Keine Sorge, haben wir schon für dich gemacht:</p>
          <a href="https://maps.app.goo.gl/aRAE2dUUBuY46zjh8" target="_blank" rel="noopener noreferrer" className="btn btn-warning card-button">
            <i className="fas fa-map"></i> Zur Wegbeschreibung
          </a>
        </div>
      </div>
    </div>
  );
};

export default AnfahrtCard;
