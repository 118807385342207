import React, { useState } from 'react';

const WebsiteContributionCard = ({ darkMode }) => {
  const [open, setOpen] = useState(false);

  const toggle = () => {
    setOpen(!open);
  };

  return (
    <div className="col-md-4 mb-3">
      <div className={`card ${darkMode ? 'dark-mode' : ''}`}>
        <div className="card-body">
          <h4 className="card-title"><i className="fas fa-camera"></i> Fotos und Ideen zur Website</h4>
          <p className="card-text">Ihr wollt Fotos oder Ideen zur Website beisteuern?</p>
          <div className={`collapse ${open ? 'show' : ''}`}>
            <p className="card-text">Wendet euch gerne an Bene. Er wird auch während des WO:O:A die Website aktualisieren, damit wir aktuelle Bilder teilen können. ☺️</p>
          </div>
          <button className="btn btn-primary card-button" onClick={toggle}>
            {open ? 'Weniger lesen' : 'Weiterlesen'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default WebsiteContributionCard;
