// src/components/Login.jsx
import React, { useState } from 'react';
import Cookies from 'js-cookie';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';

const Login = ({ onLogin }) => {
  const [password, setPassword] = useState('');
  const correctPassword = 'KobemaeuseFuerHedwig'; // Setze hier dein Passwort

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password === correctPassword) {
      Cookies.set('loggedIn', 'true', { expires: 7 }); // Cookie setzen, gültig für 7 Tag
      onLogin(true);
    } else {
      alert('Falsches Passwort');
    }
  };

  return (
    <Container className="d-flex vh-100">
      <Row className="justify-content-center align-self-center w-100">
        <Col md={6} lg={4}>
          <h1 className="text-center mb-4">Login</h1>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formPassword">
              <Form.Label>Passwort</Form.Label>
              <Form.Control 
                type="password" 
                value={password} 
                onChange={(e) => setPassword(e.target.value)} 
                placeholder="Passwort eingeben" 
              />
            </Form.Group>
            <Button variant="primary" type="submit" className="w-100 mt-3">
              Login
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
