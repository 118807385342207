import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import './CookieBanner.css';

const CookieBanner = () => {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const isCookieAccepted = Cookies.get('cookieAccepted');
    if (!isCookieAccepted) {
      setShowBanner(true);
    }
  }, []);

  const handleAccept = () => {
    Cookies.set('cookieAccepted', 'true', { expires: 7 });
    setShowBanner(false);
  };

  if (!showBanner) return null;

  return (
    <div className="cookie-banner">
      <img src={`${process.env.PUBLIC_URL}/images/kruemel.png`} alt="Kruemel" className="cookie-image" />
      <div className="cookie-message">
        <p>Wir speichern lokale Kekse für deinen Anmeldestatus und die Darkmode-Präferenz.</p>
        <button className="btn btn-primary" onClick={handleAccept}>Deal with it 😎</button>
      </div>
    </div>
  );
};

export default CookieBanner;
