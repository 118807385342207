import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './Home.css';
import Cookies from 'js-cookie';

import SpecialYearCard from './HomeCards/SpecialYearCard';
import CulinaryHighlightsCard from './HomeCards/CulinaryHighlightsCard';
import SwimmingCard from './HomeCards/SwimmingCard';
import GoldRushCard from './HomeCards/GoldRushCard';
import FatherAndSonsCard from './HomeCards/FatherAndSonsCard';
import AnfahrtCard from './HomeCards/AnfahrtCard';
import TicketsCard from './HomeCards/TicketsCard';
import HinweiseCard from './HomeCards/HinweiseCard';

import ShirtsCard from './HomeCards/ShirtsCard';
import VideoCard from './HomeCards/VideoCard';
import WebsiteContributionCard from './HomeCards/WebsiteContributionCard';
import UpdatesCard from './HomeCards/UpdatesCard';

const Home = () => {
  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    const darkModeCookie = Cookies.get('darkMode') === 'true';
    setDarkMode(darkModeCookie);
    if (darkModeCookie) {
      document.body.classList.add('dark-mode');
    } else {
      document.body.classList.remove('dark-mode');
    }
  }, []);

  const toggleDarkMode = () => {
    const newDarkMode = !darkMode;
    setDarkMode(newDarkMode);
    if (newDarkMode) {
      document.body.classList.add('dark-mode');
      Cookies.set('darkMode', 'true', { expires: 7 });
    } else {
      document.body.classList.remove('dark-mode');
      Cookies.set('darkMode', 'false', { expires: 7 });
    }
  };

  return (
    <div className="container-fluid mt-2">
      <div className="row">
        <div className="col">
          <div className="dark-mode-toggle-wrapper">
            <i className="fas fa-sun"></i>
            <label className="dark-mode-toggle">
              <input type="checkbox" checked={darkMode} onChange={toggleDarkMode} />
              <span className="slider round"></span>
            </label>
            <i className="fas fa-moon"></i>
          </div>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col text-center">
          <div className="titelbild-container">
            <img src={`${process.env.PUBLIC_URL}/images/Titelbild2024.png`} alt="Titelbild 2024" className="img-fluid titelbild" />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <h1>Willkommen zum Woosmer Open Air 2024</h1>
        </div>
      </div>

      <div className="row mb-4">
        <SpecialYearCard darkMode={darkMode} />
        <GoldRushCard darkMode={darkMode} />
        <HinweiseCard darkMode={darkMode} />
      </div>

      <div className="row mb-4">
        <TicketsCard darkMode={darkMode} />
        <AnfahrtCard darkMode={darkMode} />
        <SwimmingCard darkMode={darkMode} />
      </div>

      <div className="row mb-4">
        <FatherAndSonsCard darkMode={darkMode} />
        <CulinaryHighlightsCard darkMode={darkMode} />
        <ShirtsCard darkMode={darkMode} />
      </div>

      <div className="row mb-4">
        <VideoCard darkMode={darkMode} />
        <WebsiteContributionCard darkMode={darkMode} />
        <UpdatesCard darkMode={darkMode} />
      </div>
    </div>
  );
};

export default Home;
