import React, { useState } from 'react';

const CulinaryHighlightsCard = ({ darkMode }) => {
  const [open, setOpen] = useState(false);

  const toggle = () => {
    setOpen(!open);
  };

  return (
    <div className="col-md-4 mb-3">
      <div className={`card ${darkMode ? 'dark-mode' : ''}`}>
        <div className="card-body">
          <h4 className="card-title"><i className="fas fa-utensils"></i> Kulinarische Highlights</h4>
          <p className="card-text">Essenstechnisch erwartet euch eine durchgehend geöffnete Salatbar. Ihr könnt eure Fladenbrote, Brötchen oder ähnliches nach Belieben mit verschiedenen Salaten belegen. Dazu gibt es Gyros in fleischiger und veganer Variante.</p>
          <div className={`collapse ${open ? 'show' : ''}`}>
          <img src={`${process.env.PUBLIC_URL}/images/Buffet.jpg`} alt="Father and Sons" className="img-fluid my-2" />
            <p className="card-text">Jeder kann gerne noch eigene Zutaten mitbringen, aber Saucen, Salate und weitere Beilagen sind bereits inklusive. Wie in den letzten Jahren gilt: Wer spezielle Wünsche hat, bringt diese selbst mit. Ansonsten wird gegessen und getrunken, was auf dem Tisch steht.</p>
            
          </div>
          <button className="btn btn-primary card-button" onClick={toggle}>
            {open ? 'Weniger lesen' : 'Weiterlesen'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CulinaryHighlightsCard;
