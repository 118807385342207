import React from 'react';

const Timetable = () => {
  const calendarUrl = "https://calendar.google.com/calendar/embed?src=194fb2ac5cf592864f509a1308f5d7c130bb88c9b6ced9e6f90017cc94f36e15@group.calendar.google.com&ctz=Europe/Berlin&mode=WEEK&dates=20240812/20240818&showTitle=0&showPrint=0&showTabs=0&showCalendars=0&showNav=0";

  return (
    <div className="container">
      <h1>Timetable</h1>
      <iframe 
        src={calendarUrl} 
        style={{ border: 0, width: '100%', height: '600px', overflow: 'hidden' }} 
        title="Festival Calendar"
      ></iframe>
    </div>
  );
};

export default Timetable;
