import React from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import './Navbar.css';

const NavigationBar = () => {
  return (
    <Navbar bg="dark" variant="dark" expand="lg" className="custom-navbar">
      <div className="container-fluid">
        <Navbar.Brand as={Link} to="/">WO:O:A</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link as={Link} to="/timetable">Timetable</Nav.Link>
            {/* Removed Placeholder link */}
            <NavDropdown title="Archiv" id="basic-nav-dropdown" className="hover-dropdown">
              <NavDropdown.Item as={Link} to="/archive/2020">2020</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/archive/2021">2021</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/archive/2022">2022</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/archive/2023">2023</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
};

export default NavigationBar;
