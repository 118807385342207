// src/components/YearArchive.jsx
import React from 'react';
import { useParams } from 'react-router-dom';
import InfiniteImageGallery from './InfiniteImageGallery';

const YearArchive = () => {
  const { year } = useParams();

  return (
    <div>
      <h2>Archiv {year}</h2>
      <InfiniteImageGallery year={year} />
    </div>
  );
};

export default YearArchive;
