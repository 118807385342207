import React from 'react';

const Placeholder = () => {
  return (
    <div>
      <h1>Platzhalter</h1>
      {/* Inhalte für den Platzhalter hinzufügen */}
    </div>
  );
}

export default Placeholder;
