import React from 'react';

const SwimmingCard = ({ darkMode }) => {
  return (
    <div className="col-md-4 mb-3">
      <div className={`card ${darkMode ? 'dark-mode' : ''}`}>
        <div className="card-body">
          <h4 className="card-title"><i className="fas fa-swimmer"></i> Vergesst eure Badesachen nicht!</h4>
          <p className="card-text">
            Die Röcknitz führt momentan noch relativ viel Wasser. Mal sehen, wie es in zwei Wochen aussieht, aber bringt sicherheitshalber eure Badesachen mit.
          </p>
          <a href="https://www.google.com/search?q=Wetter+Woosmer+Vielank" target="_blank" rel="noopener noreferrer" className="btn btn-warning card-button">
            <i className="fas fa-cloud-sun"></i> Zur Wetterprognose
          </a>
        </div>
      </div>
    </div>
  );
};

export default SwimmingCard;
