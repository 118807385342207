import React, { useState } from 'react';

const GoldRushCard = ({ darkMode }) => {
  const [open, setOpen] = useState(false);

  const toggle = () => {
    setOpen(!open);
  };

  return (
    <div className="col-md-4 mb-3">
      <div className={`card ${darkMode ? 'dark-mode' : ''}`}>
        <div className="card-body">
          <h4 className="card-title"><i className="fas fa-gem"></i> Motto: Goldrausch</h4>
          <p className="card-text">Das Woosmer Open Air steht dieses Jahr unter dem Motto "Goldrausch". Was das genau bedeutet und was euch erwartet, erfahrt ihr vor Ort.</p>
          <div className={`collapse ${open ? 'show' : ''}`}>
            <p className="card-text">Wir hoffen, dass ihr mit Spiel, Spaß und Freude dabei seid und das Motto auf vielfältige Weise erleben könnt.</p>
            <figure>
              <img src={`${process.env.PUBLIC_URL}/images/gold.jpg`} alt="Gold schürfen" className="img-fluid mt-3" />
              <figcaption className="text-center mt-2">Norbert hat bereits Gold geschürft!</figcaption>
            </figure>
          </div>
          <button className="btn btn-primary card-button" onClick={toggle}>
            {open ? 'Weniger lesen' : 'Weiterlesen'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default GoldRushCard;
