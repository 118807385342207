import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Cookies from 'js-cookie';
import NavigationBar from './components/Navbar';
import Home from './components/Home';
import Timetable from './components/Timetable';
import Placeholder from './components/Placeholder';
import Archive from './components/Archive';
import Login from './components/Login';
import Footer from './components/Footer'; // Importiere den Footer
import CookieBanner from './components/CookieBanner'; // Importiere das CookieBanner
import './App.css';

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const loggedIn = Cookies.get('loggedIn');
    if (loggedIn) {
      setIsLoggedIn(true);
    }
    window.focus(); // Fokussiert das Fenster beim Laden der Seite
  }, []);

  return (
    <Router>
      {isLoggedIn ? (
        <>
          <NavigationBar />
          <div className="container mt-4">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/timetable" element={<Timetable />} />
              <Route path="/placeholder" element={<Placeholder />} />
              <Route path="/archive/*" element={<Archive />} />
            </Routes>
          </div>
          <Footer /> {/* Füge den Footer hier ein */}
          <CookieBanner /> {/* Füge das Cookie-Banner hier ein */}
        </>
      ) : (
        <Login onLogin={setIsLoggedIn} />
      )}
    </Router>
  );
};

export default App;
