import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer bg-dark text-center text-white">
      <div className="container p-3">
        <span>Mit ❤️ für Euch!</span>
      </div>
    </footer>
  );
};

export default Footer;
