import React, { useState } from 'react';

const HinweiseCard = ({ darkMode }) => {
  const [open, setOpen] = useState(false);

  const toggle = () => {
    setOpen(!open);
  };

  return (
    <div className="col-md-4 mb-3">
      <div className={`card ${darkMode ? 'dark-mode' : ''}`}>
        <div className="card-body">
          <h4 className="card-title"><i class="fa-solid fa-circle-info"></i> Hinweise</h4>
          <p className="card-text">Unsere Gastgeber bitten euch ganz lieb darum, nachfolgendes zu berücksichtigen:</p>
          <div className={`collapse ${open ? 'show' : ''}`}>
            <p className="card-text"><i class="fa-solid fa-person-walking"></i> <i class="fa-solid fa-road"></i> <i class="fa-solid fa-water-ladder"></i><br />Seid so nett und lauft möglichst vor dem Haus (Straßenseite) zur Rögnitz, damit wir Tatjanas und Ricos Rasen nicht überstrapazieren.</p>
            <p className="card-text"><i class="fa-solid fa-sink"></i> <i class="fa-solid fa-pump-soap"></i> <i className="fas fa-utensils"></i> <br />Der Waschtisch steht draußen bereit. Jeder ist eingeladen, das dreckige Geschirr zusammen zu sammeln, es abzuwaschen und in der Küche sauber wieder abzustellen.</p>
            <p className="card-text"><i className="fas fa-toilet"></i> <i class="fa-solid fa-toilet-paper"></i> <i class="fa-solid fa-shower"></i><br />Julian, Basti und Thomas haben das WC im unteren Teil des Hauses verschönert und dort kann auch geduscht werden. Es steht auch wieder die Außendusche bereit.</p>
            <p className="card-text"><i className="fas fa-shoe-prints"></i> <i class="fa-solid fa-ban"></i> <i class="fa-solid fa-stairs"></i><br />Wer ein dringendes Bedürfnis hat und unten vor der verschlossenen Tür steht, kann die Toiletten im oberen Teil des Hauses benutzen. Bitte vorher die Schuhe ausziehen. Thomas hat einen neuen Teppich verlegt.</p>
            <p className="card-text"><i class="fa-solid fa-stairs"></i> <i class="fa-solid fa-house-circle-xmark"></i> <i class="fa-solid fa-people-roof"></i><br />Ansonsten gilt: Der obere Teil des Hauses ist Gothscher Bereich.</p>
            <p className="card-text"><br /> Vielen Dank!</p><br />
          </div>
          <button className="btn btn-primary card-button" onClick={toggle} style={{ marginTop: '10px' }}>
            {open ? 'Weniger lesen' : 'Weiterlesen'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default HinweiseCard;
