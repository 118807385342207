import React, { useState } from 'react';

const ShirtsCard = ({ darkMode }) => {
  const [open, setOpen] = useState(false);

  const toggle = () => {
    setOpen(!open);
  };

  return (
    <div className="col-md-4 mb-3">
      <div className={`card ${darkMode ? 'dark-mode' : ''}`}>
        <div className="card-body">
          <h4 className="card-title"><i className="fas fa-tshirt"></i> Shirts mitbringen</h4>
          <p className="card-text">Ihr habt noch ein Shirt, das einfarbig - am besten schwarz oder weiß - ist?</p>
          <div className={`collapse ${open ? 'show' : ''}`}>
            <p className="card-text">Das sollten wir ändern! 😉</p>
          </div>
          <button className="btn btn-primary card-button" onClick={toggle}>
            {open ? 'Weniger lesen' : 'Weiterlesen'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ShirtsCard;
