import React, { useState } from 'react';

const UpdatesCard = ({ darkMode }) => {
  const [open, setOpen] = useState(false);

  const toggle = () => {
    setOpen(!open);
  };

  return (
    <div className="col-md-4 mb-3">
      <div className={`card ${darkMode ? 'dark-mode' : ''}`}>
        <div className="card-body">
          <h4 className="card-title"><i className="fas fa-code"></i> Updates und Timetable</h4>
          <p className="card-text">Schaut gerne immer wieder auf diese Website & den integrierten Timetable.</p>
          <div className={`collapse ${open ? 'show' : ''}`}>
            <p className="card-text">Sie werden fortlaufend aktualisiert. Vielleicht entdeckt ihr bis zum Start auch was Neues.</p>
          </div>
          <button className="btn btn-primary card-button" onClick={toggle}>
            {open ? 'Weniger lesen' : 'Weiterlesen'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default UpdatesCard;
