import React, { useState, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import './InfiniteImageGallery.css';
import { Button } from 'react-bootstrap';

const InfiniteImageGallery = ({ year }) => {
  const [images, setImages] = useState([]);
  const [loadedImages, setLoadedImages] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  useEffect(() => {
    const fetchImages = async () => {
      setLoadedImages([]); // Galerie leeren
      setHasMore(true); // hasMore zurücksetzen
      const response = await fetch(`/images/${year}/images.json`);
      const data = await response.json();
      setImages(data);
      const initialLoadCount = window.innerWidth > 1920 ? 12 : 5;
      loadMoreImages(data, 0, initialLoadCount);
    };

    fetchImages();
  }, [year]);

  useEffect(() => {
    const handlePopState = (event) => {
      if (isOpen) {
        closeLightbox();
      }
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [isOpen]);

  const loadMoreImages = (allImages, start, count = 5) => {
    const newImages = allImages.slice(start, start + count).map((filename) => ({
      src: `/images/${year}/${filename}`,
      alt: filename,
    }));
    setLoadedImages((prevImages) => [...prevImages, ...newImages]);

    if (start + count >= allImages.length) {
      setHasMore(false);
    }
  };

  const openLightbox = (index) => {
    setPhotoIndex(index);
    setIsOpen(true);
    enterFullScreen();
    window.history.pushState({ isOpen: true }, ''); // Fügt einen neuen Eintrag zur History hinzu
  };

  const closeLightbox = () => {
    setIsOpen(false);
    exitFullScreen();
    window.history.go(-1); // Entfernt den letzten Eintrag aus der History
  };

  const enterFullScreen = () => {
    if (document.documentElement.requestFullscreen) {
      document.documentElement.requestFullscreen();
    } else if (document.documentElement.mozRequestFullScreen) {
      document.documentElement.mozRequestFullScreen();
    } else if (document.documentElement.webkitRequestFullscreen) {
      document.documentElement.webkitRequestFullscreen();
    } else if (document.documentElement.msRequestFullscreen) {
      document.documentElement.msRequestFullscreen();
    }
  };

  const exitFullScreen = () => {
    if (document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
  };

  return (
    <div>
      <InfiniteScroll
        dataLength={loadedImages.length}
        next={() => loadMoreImages(images, loadedImages.length)}
        hasMore={hasMore}
        loader={<h4>Loading...</h4>}
        endMessage={<p style={{ textAlign: 'center' }}>End of images</p>}
      >
        <div className="image-grid">
          {loadedImages.map((image, index) => (
            <div key={index} className="image-wrapper">
              <img
                src={image.src}
                alt={image.alt}
                className="responsive-image"
                onClick={() => openLightbox(index)}
              />
            </div>
          ))}
        </div>
      </InfiniteScroll>

      {hasMore && (
        <div className="load-more-container">
          <Button onClick={() => loadMoreImages(images, loadedImages.length, 5)} variant="primary">Mehr laden</Button>
        </div>
      )}

      {isOpen && loadedImages[photoIndex] && (
        <Lightbox
          mainSrc={loadedImages[photoIndex].src}
          onCloseRequest={closeLightbox}
          enableZoom={false}
          imageCaption={loadedImages[photoIndex].alt}
        />
      )}
    </div>
  );
};

export default InfiniteImageGallery;
