import React from 'react';

const TicketsCard = ({ darkMode }) => {
  return (
    <div className="col-md-4 mb-3">
      <div className={`card ${darkMode ? 'dark-mode' : ''}`}>
        <div className="card-body">
          <h4 className="card-title"><i className="fas fa-ticket-alt"></i> Tickets</h4>
          <p className="card-text">Der Eintritt kostet 40&nbsp;€ pro Person. Kinder bis 20 Jahre sind frei.</p>
          <p className="card-text">Geld könnt Ihr Jules bar oder über PayPal geben.</p>
          <a href="https://www.paypal.me/wooa22" target="_blank" rel="noopener noreferrer" className="btn btn-warning card-button">
            <i className="fa-brands fa-paypal"></i> Jetzt über PayPal erledigen
          </a>
        </div>
      </div>
    </div>
  );
};

export default TicketsCard;
