import React, { useState } from 'react';

const VideoCard = ({ darkMode }) => {
  const [videoVisible, setVideoVisible] = useState(false);

  const showVideo = () => {
    setVideoVisible(true);
  };

  return (
    <div className="col-md-4 mb-3">
      <div className={`card ${darkMode ? 'dark-mode' : ''}`}>
        <div className="card-body">
          <h4 className="card-title"><i className="fas fa-video"></i> Video</h4>
          <p className="card-text">Erlebe einen Vorgeschmack auf das Festival mit unserem exklusiven Video.</p>
          {!videoVisible ? (
            <button className="btn btn-warning card-button" onClick={showVideo}>
              <i className="fas fa-play"></i> Jetzt ansehen!
            </button>
          ) : (
            <div className="video-container">
              <video className="embed-responsive-item" controls>
                <source src="/video/WOOA.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default VideoCard;
